import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const SpotOnO = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 60 60" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.8286 0.000130735C23.9026 0.0339943 18.1193 1.8222 13.2084 5.13916C8.29757 8.45613 4.47909 13.1532 2.23472 18.6379C-0.00963926 24.1226 -0.579335 30.1491 0.597508 35.9572C1.77435 41.7653 4.64501 47.0947 8.84733 51.2732C13.0496 55.4516 18.3954 58.2919 24.2101 59.4356C30.0248 60.5793 36.0479 59.9753 41.5197 57.6997C46.9915 55.4241 51.6668 51.579 54.9557 46.6493C58.2447 41.7196 59.9999 35.9262 60 30.0001C59.9891 26.0491 59.2001 22.1389 57.6778 18.4929C56.1556 14.8468 53.9301 11.5364 51.1284 8.75055C48.3266 5.96471 45.0035 3.75808 41.3489 2.25667C37.6942 0.755259 33.7796 -0.011517 29.8286 0.000130735ZM29.8286 43.0287C27.255 43.014 24.7434 42.2375 22.6106 40.797C20.4779 39.3565 18.8196 37.3166 17.8448 34.9347C16.8701 32.5528 16.6227 29.9355 17.1338 27.4131C17.6448 24.8908 18.8915 22.5762 20.7165 20.7616C22.5415 18.9469 24.8631 17.7134 27.3883 17.2167C29.9136 16.72 32.5293 16.9823 34.9057 17.9706C37.282 18.9588 39.3124 20.6287 40.7408 22.7696C42.1691 24.9105 42.9314 27.4265 42.9314 30.0001C42.9273 31.7162 42.585 33.4145 41.9242 34.9982C41.2633 36.5819 40.2969 38.0198 39.08 39.2298C37.8631 40.4398 36.4197 41.398 34.8323 42.0499C33.2449 42.7017 31.5446 43.0343 29.8286 43.0287Z"
        fill="currentColor"
      />
    </Icon>
  );
};
