type TGetDealDiscountDataReturn = {
  description: string;
  title: string;
};
export enum DiscountAdjustmentKinds {
  DiscountAdjustmentKindAmountOff = 1,
  DiscountAdjustmentKindSetPrice = 2,
  DiscountAdjustmentKindPriceLevel = 3,
  DiscountAdjustmentKindPercentOff = 4,
  DiscountAdjustmentKindPopupSetPrice = 5,
  DiscountAdjustmentKindPopupAmountOff = 6,
  DiscountAdjustmentKindPopupPercentOff = 7,
}
export const getDealDiscountData = (
  discountType: DiscountAdjustmentKinds,
  discountValue: number,
  minOrderValue?: number,
  maxDiscountValue?: number
): TGetDealDiscountDataReturn => {
  if (discountType === DiscountAdjustmentKinds.DiscountAdjustmentKindAmountOff) {
    if (!minOrderValue && !maxDiscountValue) {
      return { title: `$${discountValue} off`, description: 'Entire order' };
    }

    if (minOrderValue && !maxDiscountValue) {
      return { title: `$${discountValue} off`, description: `Orders over $${minOrderValue}` };
    }
  }
  if (discountType === DiscountAdjustmentKinds.DiscountAdjustmentKindPercentOff) {
    const parsedDiscountValue = Number.isInteger(discountValue)
      ? discountValue
      : Math.round(discountValue * 100);
    if (!minOrderValue && !maxDiscountValue) {
      return {
        title: `${parsedDiscountValue}% off`,
        description: 'Entire order',
      };
    }

    if (minOrderValue && !maxDiscountValue) {
      return {
        title: `${parsedDiscountValue}% off`,
        description: `Orders over $${minOrderValue}`,
      };
    }
    if (!minOrderValue && maxDiscountValue) {
      return {
        title: `${parsedDiscountValue}% off`,
        description: `Entire order, up to $${maxDiscountValue} off`,
      };
    }

    if (minOrderValue && maxDiscountValue) {
      return {
        title: `${parsedDiscountValue}% off`,
        description: `Orders over $${minOrderValue}, up to $${maxDiscountValue} off`,
      };
    }
  }
  return { title: '', description: '' };
};

export const formatDiscountValue = (
  adjustmentKind: DiscountAdjustmentKinds,
  adjustmentValue: string
) => {
  if (adjustmentKind === DiscountAdjustmentKinds.DiscountAdjustmentKindPercentOff) {
    return adjustmentValue + '%';
  }
  if (adjustmentKind === DiscountAdjustmentKinds.DiscountAdjustmentKindAmountOff) {
    return '$' + adjustmentValue;
  }
  return '';
};
