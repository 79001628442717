import React, { useMemo } from 'react';
import { Box, Flex, FlexProps } from '@chakra-ui/react';
import { isObject } from '@olo-web/utils/common/functions';
import { BusinessDeals } from '@olo-web/assets/icons/BusinessDeals.ui';
import { SpotOnO } from '@olo-web/assets/icons/SpotOnO.ui';
import { GiftCardIcon } from '@olo-web/assets/icons/payments/GiftCardIcon.ui';
import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';
import { useCombinedTheme } from '@olo-web/theme/hooks';
import { useLoyalty } from '@olo-web/domain/customer/hooks/useLoyalty';
import { DiscountType } from '@olo-web/domain/orders/types';

const decoratedLineItemStyles: FlexProps = {
  alignContent: 'flex-start',
  justify: 'space-between',
  wordBreak: 'break-word',
  fontSize: 14,
  lineHeight: '22px',
  py: 1,
  borderRadius: 'md',
};

export function ProductTotalsItem(props: any): JSX.Element {
  const { title, info, isHistorical } = props;
  const { colors } = useCombinedTheme();
  const { data: loyalty } = useLoyalty();

  const items = useMemo(() => {
    const promoCodeDealsStyles = {
      title: 'Promotion',
      color: `linear-gradient(0deg, rgba(255, 255, 255, 0.90), rgba(255, 255, 255, 0.90)), ${colors.primary[400]}`,
      icon: {
        component: <BusinessDeals color="primary.300" />,
        styles: {
          width: '13px',
          height: '13px',
        },
      },
    };
    const promoCodeRewardStyles = {
      title: 'Reward',
      color: 'loyalty.50',
      icon: {
        component: <SpotOnO color="loyalty.400" />,
        styles: {
          width: '16px',
          height: '16px',
        },
      },
    };
    const promoCodeGiftCard = {
      color: 'linear-gradient(0deg, rgba(255, 255, 255, 0.92), rgba(255, 255, 255, 0.92)), #47516E',
      icon: {
        component: <GiftCardIcon h={6} w={7} />,
      },
    };
    return {
      [DiscountType.PROMO_CODE]: promoCodeDealsStyles,
      [DiscountType.DEAL]: promoCodeDealsStyles,
      [DiscountType.REWARD]: promoCodeRewardStyles,
      GiftCard: promoCodeGiftCard,
    };
  }, [colors]);

  const itemUiSetting = useMemo(() => {
    if (!info || !(isObject(info) && info?.spotOnRewardId)) return DiscountType.PROMO_CODE;

    if (loyalty?.deals[info?.spotOnRewardId]) {
      return DiscountType.DEAL;
    }

    if (loyalty?.rewards[info?.spotOnRewardId]) {
      return DiscountType.REWARD;
    }

    if (isHistorical && info?.promotion_type && items[info?.promotion_type]) {
      return info?.promotion_type;
    }

    return DiscountType.PROMO_CODE;
  }, [info, loyalty?.deals, loyalty?.rewards, isHistorical, items]);

  if (title === 'GiftCard') {
    return (
      <Flex
        as="tr"
        {...decoratedLineItemStyles}
        bg={`${items[title].color}`}
        data-testid="redeemed-gift-card"
      >
        <Box
          as="td"
          flex={6}
          sx={{
            svg: {
              display: 'inline',
            },
          }}
        >
          <Box as="span">Gift card redeemed</Box>
          <Box pl="8px" as="span">
            {items[title].icon.component}
          </Box>
        </Box>
        <Box as="td" pl={4}>{`-${formatPrice(info)}`}</Box>
      </Flex>
    );
  }

  if (!isObject(info)) {
    return (
      <Flex
        w="100%"
        as="tr"
        fontSize={14}
        lineHeight="23px"
        justify="space-between"
        data-testid="totals-summary-line-item"
      >
        <Box
          as="th"
          scope="row"
          fontWeight={title === 'Bag total' ? '600' : '400'}
          textAlign="left"
          pt={1}
          pb={1}
          style={{
            overflowWrap: 'normal',
            whiteSpace: 'normal',
          }}
        >
          {title}
        </Box>
        <Box
          as="td"
          fontWeight={title === 'Bag total' ? '600' : '400'}
          pl={4}
          pt={1}
          pb={1}
          verticalAlign="bottom"
          textAlign="right"
        >
          {formatPrice(info)}
        </Box>
      </Flex>
    );
  }

  return (
    <Flex
      as="tr"
      {...decoratedLineItemStyles}
      background={items[itemUiSetting].color}
      data-testid="redeemed-discount"
      borderRadius="4px"
    >
      <Box
        as="td"
        flex={6}
        sx={{
          svg: {
            display: 'inline',
            ...items[itemUiSetting].icon.styles,
          },
        }}
      >
        <Box as="span">{`${items[itemUiSetting].title}`}</Box>
        <Box pl="8px" as="span" w="16px" h="16px">
          {items[itemUiSetting].icon.component}
        </Box>
      </Box>
      <Box as="td" pl={4}>
        {`${formatPrice(info.displayAmount)}`}
      </Box>
    </Flex>
  );
}
