import React, { forwardRef, useRef, PropsWithChildren } from 'react';
import { useMergeRefs } from '@chakra-ui/react';
import { motion, MotionProps } from 'framer-motion';

import { useMeasure } from '@olo-web/utils/common/hooks/useMeasure';
import { getAutoHeightDuration } from '@olo-web/utils/common/functions/getAutoHeightDuration';

export const AnimateHeight = forwardRef<HTMLDivElement, PropsWithChildren<MotionProps>>(
  ({ transition, ...rest }, ref) => {
    const innerRef = useRef<HTMLInputElement>(null);
    const bounds = useMeasure(innerRef);

    const refs = useMergeRefs(ref, innerRef);

    return (
      <motion.div
        {...rest}
        ref={refs}
        initial={{ opacity: 0, height: 0, width: '100%', margin: 0, padding: 0 }}
        animate={{ opacity: 1, height: 'auto', width: '100%', margin: 0, padding: 0 }}
        exit={{ opacity: 0, height: 0 }}
        inherit={false}
        transition={
          transition || {
            duration: getAutoHeightDuration(bounds.height),
          }
        }
      />
    );
  }
);
