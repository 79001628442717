import { useMerchant } from '@domain/merchants/queries/useMerchant';

export const useIsOrderTypeSelectorHidden = (): boolean => {
  const { data } = useMerchant();
  const hideUnavailableOrderTypes = data.hideUnavailableOrderTypes;
  const pickup = data?.orderTypes?.['pickup'];
  const delivery = data?.orderTypes?.['doordashdrive'] || data?.orderTypes?.['delivery'];

  return !!pickup !== !!delivery && hideUnavailableOrderTypes;
};
