import { useMemo } from 'react';
import { useMerchantTheme } from '@domain/merchants/queries/useMerchantTheme';
import { useCustomThemeDraftState } from '@olo-web/client-state';
import { IMerchantSettings } from '@olo-web/types/merchantTheme.interface';
import { useIsUpdateThemeQueryParamTrue } from './useIsUpdateThemeQueryParamTrue';

export const useCombinedMerchantSettings = (): IMerchantSettings => {
  const { data: savedTheme } = useMerchantTheme();
  const { settings: draftSettings } = useCustomThemeDraftState();
  const isUpdateTheme = useIsUpdateThemeQueryParamTrue();

  const combinedSettings = useMemo(() => {
    const savedSettings = savedTheme?.settings ?? {};
    if (!isUpdateTheme) return savedSettings;

    return {
      ...savedSettings,
      ...(draftSettings ?? {}),
    };
  }, [isUpdateTheme, draftSettings, savedTheme?.settings]);

  return combinedSettings;
};
