import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const GiftCardIcon = (props: IconProps) => {
  return (
    <Icon viewBox="0 0 41 26" fill="none" {...props}>
      <rect x="0.75" y="0.75" width="32.5" height="20.5" rx="3.25" fill="currentColor" />
      <rect x="0.75" y="0.75" width="32.5" height="20.5" rx="3.25" fill="white" />
      <rect
        x="0.75"
        y="0.75"
        width="32.5"
        height="20.5"
        rx="3.25"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M23.2222 7.88965H10.7778C10.3482 7.88965 10 8.23787 10 8.66743V10.223C10 10.6525 10.3482 11.0008 10.7778 11.0008H23.2222C23.6518 11.0008 24 10.6525 24 10.223V8.66743C24 8.23787 23.6518 7.88965 23.2222 7.88965Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17 7.88965V18.0008"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4455 11V16.4444C22.4455 16.857 22.2816 17.2527 21.9899 17.5444C21.6982 17.8361 21.3025 18 20.89 18H13.1122C12.6996 18 12.304 17.8361 12.0123 17.5444C11.7205 17.2527 11.5566 16.857 11.5566 16.4444V11"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.5011 7.88923C12.9854 7.88923 12.4908 7.68437 12.1262 7.31971C11.7615 6.95506 11.5566 6.46048 11.5566 5.94478C11.5566 5.42909 11.7615 4.93451 12.1262 4.56985C12.4908 4.2052 12.9854 4.00034 13.5011 4.00034C14.2514 3.98727 14.9867 4.35132 15.611 5.04502C16.2353 5.73872 16.7197 6.72988 17.0011 7.88923C17.2824 6.72988 17.7668 5.73872 18.3912 5.04502C19.0155 4.35132 19.7508 3.98727 20.5011 4.00034C21.0168 4.00034 21.5114 4.2052 21.876 4.56985C22.2407 4.93451 22.4455 5.42909 22.4455 5.94478C22.4455 6.46048 22.2407 6.95506 21.876 7.31971C21.5114 7.68437 21.0168 7.88923 20.5011 7.88923"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
};
