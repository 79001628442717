import React, { useMemo } from 'react';
import {
  Box,
  Flex,
  TableContainer,
  Table,
  VisuallyHidden,
  Text,
  Alert,
  Spinner,
  Center,
} from '@chakra-ui/react';
import { ProductTotalsItem } from './ProductTotalsItem.ui';
import { EProductTotalsItems } from '@olo-web/types/enums/productTotalsItems.enum';
import { useGiftCardBalance } from '@domain';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { useIsClient } from '@olo-web/utils/common/hooks/useIsClient';
import { sumPayments } from '@olo-web/domain/orders/functions/sumPayments';
import { usePaymentMethodValues } from '@olo-web/domain/payments/hooks';
import {
  useIsOrderConfirmationPage,
  useIsOrderReceiptPage,
  useIsDineIn,
} from '@olo-web/utils/common/hooks';

import { AnimatePresence } from 'framer-motion';
import { AnimateHeight } from '@olo-web/components/atoms/AnimateHeight';
import { useCustomerState, useSavedDineInContextState } from '@olo-web/client-state';
import { formatPrice } from '@olo-web/domain/orders/functions/formatPrice';
import { useIsOnPremisePaymentOnly } from '@olo-web/components/templates/Checkout/common/hooks/useIsOnPremisePaymentOnly';
import { BusinessDeals } from '@olo-web/assets/icons/BusinessDeals.ui';
import { IOrderDiscount } from '@domain/orders/types';
import { formatDiscountValue } from '@olo-web/utils/common/functions/getDealDiscountDescription';

const surchargeReducer = (acc, item) =>
  Number(item?.displayAmount)
    ? {
        ...acc,
        [item.surchargeName]: item.displayAmount,
      }
    : acc;

interface ProductTotalsProps {
  orderId?: string;
  children?: JSX.Element;
  includeTip?: boolean;
  includeOtherPayments?: boolean;
  includeGiftCard?: boolean;
  otherPaymentsAreServerPayments?: boolean;
  showDisplayTotalAmount?: boolean;
  includeTipInTotal?: boolean; // when tip isn't a line item because it's shown with the tip selector, but you want to include it in the total
}

export function ProductTotals({
  includeTip = false,
  includeOtherPayments = false,
  includeGiftCard = false,
  children,
  otherPaymentsAreServerPayments = false,
  includeTipInTotal = false,
}: ProductTotalsProps): JSX.Element {
  const { data: order } = useOrder();
  const { data: giftCard } = useGiftCardBalance();
  const isClient = useIsClient();
  const savedDineInState = useSavedDineInContextState();
  const { tipAmount, giftCardCoversEntireOrderAmount, isCash } = usePaymentMethodValues();
  const isOrderConfirmationPage = useIsOrderConfirmationPage();
  const isOrderReceiptPage = useIsOrderReceiptPage();
  const isDineInOrder = useIsDineIn();
  const isOnPremisPaymentOnly = useIsOnPremisePaymentOnly();
  const customerState = useCustomerState();
  const minOrderMarketingDealPromo =
    customerState &&
    customerState.promos &&
    (Object.values(customerState.promos).find(
      (promo: IOrderDiscount) =>
        promo.promotionType === 1 && !!promo.minOrderValue && promo.shouldBeAutoApplied
    ) as IOrderDiscount);

  const itemTotal: number = useMemo(() => {
    if (!order) {
      return 0;
    }

    const totalSurchargeable: number =
      order?.surcharges
        ?.filter((s) => s.taxable)
        .reduce((acc, curr) => acc + Number(curr.displayAmount), 0) || 0;

    const preDiscountSubtotal: number = order?.preDiscountSubtotal
      ? Number(order?.preDiscountSubtotal)
      : 0;

    return preDiscountSubtotal - totalSurchargeable;
  }, [order]);

  //Order of items :
  // - Bag total (Sum of items minus surchargeable taxes)
  // - Promos or rewards
  // - Taxable surcharges
  // - Sales taxes
  // - Non-taxable surcharges
  // - Other payments
  // - Tip
  // if any of the above (expect for tip) are 0, they are not shown

  const data = useMemo(() => {
    const baseMapping: {
      [key: string]: number | string | IOrderDiscount;
    } = {
      [EProductTotalsItems.ITEM_TOTAL]: itemTotal,
      ...order?.discounts?.reduce((acc, item) => ({ ...acc, [item.id]: item }), {}),
      ...order?.surcharges?.filter((surcharge) => surcharge.taxable).reduce(surchargeReducer, {}),
      ...order?.displayTaxes?.reduce(
        (acc, item) => ({ ...acc, [item.taxName]: item.displayAmount }),
        {}
      ),
      ...order?.surcharges?.filter((surcharge) => !surcharge.taxable).reduce(surchargeReducer, {}),
      [EProductTotalsItems.OTHER_PAYMENTS]: -sumPayments(
        order?.payments.filter((payment) =>
          otherPaymentsAreServerPayments
            ? payment.customer === null
            : payment.guestId !== savedDineInState?.guest?.id
        )
      ),
      [EProductTotalsItems.TIP]: tipAmount,
      [EProductTotalsItems.GIFTCARD]: giftCardCoversEntireOrderAmount
        ? Number(order?.balanceDueAmount ?? '0') + tipAmount
        : +(giftCard?.remainingBalanceAmount ?? '0'),
    };

    const mapping = Object.entries(baseMapping).filter(([key, value]) => {
      const noValue = Number(value || 0) === 0;
      switch (key) {
        case EProductTotalsItems.TIP: {
          if (isCash) return false;

          return !noValue && includeTip;
        }
        case EProductTotalsItems.GIFTCARD:
          return !isCash && !noValue && includeGiftCard;
        case EProductTotalsItems.OTHER_PAYMENTS:
          return !noValue && includeOtherPayments;
        default:
          return !noValue;
      }
    });
    const isIOrderDiscount = (value: string | number | IOrderDiscount): value is IOrderDiscount => {
      return (
        (value as IOrderDiscount)?.promotionName !== undefined ||
        (value as IOrderDiscount)?.minOrderValue !== undefined ||
        (value as any)?.discountName !== undefined
      );
    };

    let total = mapping.reduce((sum, [key, value]) => {
      let toAdd = +value;
      if (isIOrderDiscount(value)) {
        if (value?.discountValue) {
          toAdd = +value.discountValue;
        } else {
          toAdd = +(value as any).displayAmount;
        }
      } else if (key === EProductTotalsItems.GIFTCARD) {
        toAdd = -value;
      }
      return sum + toAdd;
    }, 0);

    if (includeTipInTotal && !isOnPremisPaymentOnly) total += tipAmount;

    return {
      mapping,
      total: total.toFixed(2),
    };
  }, [
    itemTotal,
    order?.discounts,
    order?.surcharges,
    order?.displayTaxes,
    order?.payments,
    tipAmount,
    giftCardCoversEntireOrderAmount,
    giftCard?.remainingBalanceAmount,
    savedDineInState,
    order?.balanceDueAmount,
    otherPaymentsAreServerPayments,
    includeGiftCard,
    includeOtherPayments,
    includeTip,
    includeTipInTotal,
    isCash,
    isOnPremisPaymentOnly,
  ]);

  return (
    <AnimatePresence initial={false}>
      <AnimateHeight
        transition={{ duration: 0.3, delay: 0.3 }}
        style={{ width: '100%', maxWidth: '100%' }}
      >
        <TableContainer
          w="100%"
          fontWeight="400"
          data-testid="totals-summary"
          aria-labelledby="table1Caption"
          mb={-5}
        >
          {isClient && ( // This is a bandaid fix for a Hydration error (not sure why this child causes the error)
            <Table role="presentation" width="100%">
              <VisuallyHidden as="caption" id="table1Caption">
                Product Item Totals
              </VisuallyHidden>
              <Box as="tbody">
                {data.mapping.map(([key, info]) => (
                  <ProductTotalsItem
                    key={key}
                    title={key}
                    info={info}
                    isHistorical={isOrderConfirmationPage || isOrderReceiptPage}
                  />
                ))}
                {children ? (
                  <Box as="tr" w="100%">
                    {children}
                  </Box>
                ) : null}
                <Flex
                  justify="space-between"
                  as="tr"
                  mb="10px"
                  fontSize={14}
                  lineHeight="23px"
                  fontWeight="600"
                >
                  <Box as="th" textAlign="left" pt={1} pb={1}>
                    Total
                  </Box>
                  <Box
                    as="td"
                    data-testid="totals-summary-order-total"
                    verticalAlign="bottom"
                    textAlign="right"
                    pt={1}
                    pb={1}
                  >
                    {formatPrice(data.total)}
                  </Box>
                </Flex>
                {minOrderMarketingDealPromo &&
                  parseInt(minOrderMarketingDealPromo.minOrderValue) > itemTotal && (
                    <Alert
                      status="success"
                      variant="left-accent"
                      borderRadius={5}
                      height="36px"
                      position="relative"
                      marginBottom="12px"
                    >
                      <BusinessDeals color="green.500" />

                      {customerState.isLoadingDiscount ? (
                        <Center width="100%">
                          <Spinner color="green.500" />
                        </Center>
                      ) : (
                        <Text
                          fontSize={14}
                          letterSpacing="0.14px"
                          marginLeft="10px"
                          color="green.700"
                        >
                          Spend $
                          {(parseInt(minOrderMarketingDealPromo.minOrderValue) - itemTotal).toFixed(
                            2
                          )}{' '}
                          more to save{' '}
                          {formatDiscountValue(
                            minOrderMarketingDealPromo.adjustmentKind,
                            minOrderMarketingDealPromo.adjustmentValue
                          )}
                        </Text>
                      )}
                    </Alert>
                  )}
              </Box>
            </Table>
          )}
        </TableContainer>
        {isDineInOrder && order?.orderNumber && (
          <Box pt={2}>
            <Text as="i" fontSize="xs" fontWeight="400" w="100%">
              Order ID {order.orderNumber}
            </Text>
          </Box>
        )}
      </AnimateHeight>
    </AnimatePresence>
  );
}
