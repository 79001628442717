import React from 'react';
import { Icon, IconProps } from '@chakra-ui/react';

export const BusinessDeals = (props: IconProps) => (
  <Icon width="13" height="13" viewBox="0 0 13 13" {...props}>
    <path
      d="M12.6165 6.227L6.7665 0.377C6.5325 0.143 6.2075 0 5.85 0H1.3C0.585 0 0 0.585 0 1.3V5.85C0 6.2075 0.143 6.5325 0.3835 6.773L6.2335 12.623C6.4675 12.857 6.7925 13 7.15 13C7.5075 13 7.8325 12.857 8.0665 12.6165L12.6165 8.0665C12.857 7.8325 13 7.5075 13 7.15C13 6.7925 12.8505 6.461 12.6165 6.227ZM2.275 3.25C1.7355 3.25 1.3 2.8145 1.3 2.275C1.3 1.7355 1.7355 1.3 2.275 1.3C2.8145 1.3 3.25 1.7355 3.25 2.275C3.25 2.8145 2.8145 3.25 2.275 3.25Z"
      fill="currentColor"
    />
  </Icon>
);
